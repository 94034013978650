.container {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 60px;
  width: 100vw;
  background-color: brown;
}
.left {
  display: flex;
  align-items: center;
  justify-content: left;
  color: white;
  font-size: 1.5rem;
  padding-left: 25px;
  font-weight: bold;
}
