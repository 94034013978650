* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
}
.rsc {
  height: 100% !important;
  width: 100% !important;
}
.dPdbIW {
  width: 95%;
  height: 95%;
  margin: auto;
}
