.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 30vw;
  background-color: #f5f5f5;
  flex-direction: column;
  margin: auto;
  margin-top: 10%;
  border-radius: 10px;
}
.login-container h3 {
  font-size: 1.5rem;
  margin-bottom: 25px;
}
.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 30%;
  width: 100%;
}
.inputs input {
  width: 80%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.login-container button {
  width: 50%;
  height: 40px;
  margin-top: 25px;
  border: none;
  border-radius: 5px;
  background-color: brown;
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}
